import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const ProfilesWrapper = (base) => css`
  margin: 96px 0;

  ${base.MQ.phone} {
    margin: 48px 0;
  }
`

export const ProfilesText = (base) => css`
  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.large,
      color: base.colorTertiary._500,
      margin: '0px 0px 24px 0px',
      padding: '0',
    })}
  }

  ${base.MQ.phone} {
    & > h1 {
      ${js2css({
        ...base.typeTitle.small,
      })}
    }
  }
`

export const ProfilesIcons = (base) => css`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -44px;

  & > div {
    width: 176px;
    margin: 24px 44px;
    padding: 0px 0px 15px 0px;
  }

  ${base.MQ.phone} {
    display: grid;
    align-self: flex-start;
    grid-gap: 24px;
    margin: 0;

    & > div {
      width: 100%;
      max-width: 100%;
      margin: 0px;
      padding: 0px;
    }
  }
`
