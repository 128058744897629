import React from 'react'
import { LocationsWrapper, LocationsText, LocationsTiles } from './Locations.css'
import { Tile, useKinetiq } from 'kinetiq'

export const Locations = ({ title, tiles }) => {
  const kinetiq = useKinetiq()
  return (
    <div css={LocationsWrapper(kinetiq.base)}>
      <div css={LocationsText(kinetiq.base)}>
        <h1>{title}</h1>
      </div>
      <div css={LocationsTiles(kinetiq.base)}>
        {tiles.map((tile, index) => (
          <Tile key={index} {...tile} />
        ))}
      </div>
    </div>
  )
}
