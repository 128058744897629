import React from 'react'
import { ProfilesWrapper, ProfilesText, ProfilesIcons } from './Profiles.css'
import { ProfileModal } from '../ProfileModal'
import { useKinetiq } from 'kinetiq'

export const Profiles = ({ title, profiles }) => {
  const kinetiq = useKinetiq()
  return (
    <div css={ProfilesWrapper(kinetiq.base)}>
      <div css={ProfilesText(kinetiq.base)}>
        <h1>{title}</h1>
      </div>
      <div css={ProfilesIcons(kinetiq.base)}>
        {profiles.map((profile) => (
          <ProfileModal key={profile.text} {...profile} />
        ))}
      </div>
    </div>
  )
}
