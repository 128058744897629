import React from 'react'
import { useModal } from 'react-modal-hook'

import {
  ProfileModalWrapper,
  ProfileModalContainer,
  CardProfileModal,
  BulletsWrapper,
  CloseButton,
  CloseButtonWrapper,
  BioWrapper,
  CardWrapper,
} from './ProfileModal.css'
import { SVGImage } from '../SVGImage'
import { Button, CardProfile, navigateCallback, useKinetiq } from 'kinetiq'

export const ProfileModal = ({ image, first, last, text, link, bullets, bio }) => {
  const kinetiq = useKinetiq()

  const [showProfileModal, hideProfileModal] = useModal(
    ({ in: isOpen, onExited: afterClose }) => {
      const props = {
        isOpen,
        afterClose,
        onClose: hideProfileModal,
        onBackgroundClick: hideProfileModal,
        onEscapeKeydown: hideProfileModal,
        allowScroll: false,
      }

      return (
        <ProfileModalWrapper {...props} base={kinetiq.base}>
          <div css={CloseButtonWrapper}>
            <div css={CloseButton(kinetiq.base)} onClick={hideProfileModal}>
              <SVGImage variant='IconClose' />
            </div>
          </div>
          <div css={ProfileModalContainer(kinetiq.base)}>
            <div>
              <div css={CardProfileModal(kinetiq.cardProfileTokens, kinetiq.base, image)}>
                <div></div>
                <div>
                  <h2>{first}</h2>
                  <h1>{last}</h1>
                  <span>{text}</span>
                  <div>
                    <Button
                      text='LinkedIn'
                      variant='neutral'
                      onClickHandler={navigateCallback(link)}
                    />
                  </div>
                </div>
              </div>
              <div css={BulletsWrapper(kinetiq.base)}>
                {bullets.map((bulletText) => (
                  <div>{bulletText}</div>
                ))}
              </div>
            </div>
            <div css={BioWrapper(kinetiq.base)}>
              {bio.map((bioText) => (
                <p>{bioText}</p>
              ))}
            </div>
          </div>
        </ProfileModalWrapper>
      )
    },
  )

  return (
    <div css={CardWrapper(kinetiq.base)}>
      <CardProfile
        image={image}
        first={first}
        last={last}
        text={text}
        onClickHandler={showProfileModal}
      />
    </div>
  )
}
