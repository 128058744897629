import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const LocationsWrapper = (base) => css`
  margin: 96px 0;

  ${base.MQ.phone} {
    margin: 48px 0;
  }
`

export const LocationsText = (base) => css`
  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.large,
      color: base.colorTertiary._500,
      margin: '0px 0px 24px 0px',
      padding: '0',
    })}
  }

  ${base.MQ.phone} {
    & > h1 {
      ${js2css({
        ...base.typeTitle.small,
      })}
    }
  }
`

export const LocationsTiles = (base) => css`
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr);
  grid-template-rows: repeat(3, [row-start] 144px);
  grid-gap: 24px;

  div:nth-of-type(1) {
    grid-column-start: span 1;
    grid-row-start: span 3;
  }
  div:nth-of-type(2) {
    grid-column-start: span 1;
    grid-row-start: span 3;
  }
  div:nth-of-type(3) {
    grid-column-start: span 1;
  }
  div:nth-of-type(4) {
    grid-column-start: span 1;
  }
  div:nth-of-type(5) {
    grid-column-start: span 1;
  }

  ${base.MQ.phone} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 140px);
    grid-gap: 16px;

    div:nth-of-type(1) {
      grid-row-start: span 2;
    }
    div:nth-of-type(2) {
      grid-row-start: span 2;
    }
  }
`
