import { css } from '@emotion/core'
import { js2css } from 'kinetiq'
import Modal from '../Modal/Modal'

export const ProfileModalWrapper = Modal.styled`
    width: 1032px;
    min-height: 600px;
    max-height: 85vh;

    background-color: ${({ base }) => base.colorNeutral._200};
    position: relative;
    border-radius: 12px;

    box-sizing: border-box;
    padding: 18px;

    ${({ base }) => base.MQ.phone} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0px;

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`

export const CloseButtonWrapper = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 18px;
`

export const CloseButton = (base) => css`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const ProfileModalContainer = (base) => css`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;

  & > div:nth-of-type(1) {
    width: 100%;
    max-height: calc(85vh - 56px - 18px);
  }

  & > div:nth-of-type(2) {
    max-height: calc(85vh - 56px - 18px);
  }

  ${base.MQ.phone} {
    display: unset;
    width: 100vw;
    height: 100vh;

    & > div:nth-of-type(1) {
      max-height: unset;
    }

    & > div:nth-of-type(2) {
      height: unset;
      max-height: unset;
    }
  }
`

export const BulletsWrapper = (base) => css`
  ${js2css({
    ...base.typeEditorial.common,
    ...base.typeEditorial.smaller,
    color: base.colorTint._900,
  })}

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  margin-top: 25px;
  width: 100%;
  max-height: calc(85vh - 56px - 18px - 278px - 25px);

  & > div {
    position: relative;
    margin-left: 34px;
    padding: 11px 0px;

    &::after {
      position: absolute;
      content: '';
      top: 19px;
      left: -16.5px;
      background: ${base.colorTint._900};
      width: 6px;
      height: 6px;
      border-radius: 100vh;
    }
  }

  ${base.MQ.phone} {
    max-height: 100%;

    & > div {
      margin: 0px 6px 0px 20px;
    }
  }
`

export const CardProfileModal = (cardProfileTokens, base, image) => css`
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  ${js2css(cardProfileTokens.card)}

  /* image */
  & > div:nth-of-type(1) {
    border-radius: 12px;
    width: 112px;
    height: 112px;
    background: ${base.colorTint._100};
    background-image: url('${image}');
    background-position: center center;
    background-size: cover;
  }

  /* text */
  & > div:nth-of-type(2) {
    /* first */
    & > h2 {
      ${js2css({
        ...base.typeEditorial.common,
        ...base.typeEditorial.large,
        color: base.colorNeutral._1000,
        margin: '16px 0 0 0',
        padding: '0',
      })}
    }

    /* last */
    & > h1 {
      ${js2css({
        ...base.typeTitle.common,
        ...base.typeTitle.smaller,
        color: base.colorNeutral._1000,
        margin: '0',
        padding: '0',
      })}
    }

    /* text */
    & > span {
      display: block;
      ${js2css({
        ...base.typeData.common,
        ...base.typeData.smaller,
        color: base.colorTint._500,
        margin: '0',
        padding: '8px 0 16px 0',
      })}
    }

    /* button */
    & > div:nth-of-type(1) {
      display: inline-block;
    }
  }
`

export const BioWrapper = (base) => css`
  ${js2css({
    ...base.typeEditorial.common,
    ...base.typeEditorial.small,
    color: base.colorTint._500,
  })}
  width: 536px;
  max-width: 536px;
  min-width: 536px;
  margin: 0;
  padding: 0px 48px 0 25px;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  & p {
    margin: 0 0 16px 0;
    padding: 0;
  }

  ${base.MQ.phone} {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    overflow-y: unset;
    -webkit-overflow-scrolling: unset;

    padding: 24px 6px 0 6px;
  }
`

export const CardWrapper = (base) => css`
  cursor: pointer;

  &:hover {
    & > div:nth-of-type(1) {
      & > div:nth-of-type(1) {
        background-size: 180px 180px;
      }
    }
  }
`
