import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const PartnersWrapper = (background) => css`
  position: relative;
  z-index: 2;
  padding: 48px 0px;

  & > div:nth-of-type(1) {
    position: absolute;
    width: 100vw;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${background};
  }
`

export const PartnersText = (base) => css`
  text-align: center;
  margin-bottom: 48px;

  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.smallest,
      color: base.colorTertiary._500,
      margin: '0',
      padding: '0',
    })}
  }

  ${base.MQ.phone} {
    margin-bottom: 16px;
  }
`

export const PartnersContainer = (base) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -12px;

  & > div {
    width: 240px;
    min-width: 240px;
    height: 128px;
    margin: 0px 12px 24px 12px;
  }

  ${base.MQ.phone} {
    margin: 0px -8px;

    & > div {
      width: 155px;
      min-width: 155px;
      height: 80px;
      margin: 0px 8px 16px 8px;
    }
  }
`
