import React from 'react'
import { CoverBillboardWide, Callout } from 'kinetiq'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Profiles } from '../components/Profiles'
import { Locations } from '../components/Locations'
import { Partners } from '../components/Partners'
import { SVGImage } from '../components/SVGImage'
import { PageContent } from '../components/PageContent'

const AboutPage = () => (
  <Layout>
    <SEO title='About Us' />
    <CoverBillboardWide>
      <h1>About Us</h1>
      <h2>Vision</h2>
      <p>
        Qoor's vision is to reduce the barriers to entry for organizations adopting Artificial
        Intelligence, Augmented Reality/Virtual Reality, Blockchain, and Edge Computing through
        an ecosystem of customer-centric software solutions.
      </p>
      <h2>Mission</h2>
      <p>
        Qoor’s mission is to accelerate Artificial Intelligence development, reduce costs, and
        drive technological convergence for our customers—Artificial Intelligence, Augmented
        Reality/Virtual Reality, DLT/Blockchain, and Web 3.0.
      </p>
    </CoverBillboardWide>
    <PageContent>
      <Callout text='Qoor is currently on the launchpad and fueling our boosters.  We expect to begin servicing customers in 2020.' />
      <Locations
        title='Locations'
        tiles={[
          { image: '/locations/san-jose.jpg', title: 'San Jose, CA, USA', text: '' },
          { image: '/locations/vancouver.jpg', title: 'Vancouver, BC, Canada', text: '' },
          { image: '/locations/austin.jpg', title: 'Austin, TX, USA', text: 'Coming Q4 2019' },
          {
            image: '/locations/abu-dhabi.jpg',
            title: 'Abu Dhabi, UAE',
            text: 'Targeting 2020',
          },
          { image: '/locations/singapore.jpg', title: 'Singapore, SG', text: 'Targeting 2021' },
        ]}
      />
      <Profiles
        title='Team'
        profiles={[
          {
            image: '/avatars/heaviside-matthew.png',
            first: 'W. Matthew',
            last: 'Heaviside',
            text: 'President & CEO',
            link: 'https://www.linkedin.com/in/heaviside/',
            bullets: [
              '20 years managing international operations with over $1B in complex Capital Projects',
              'Deep experience in technology–facilities, computing, telecom, and energy sectors',
              'Pivotal roles at Ericsson, Hitachi, Schlumberger, VM Ware, Juniper Networks, Intuit, AT&T, and other F500 companies',
              'BBA, Cox School of Business, Southern Methodist University',
            ],
            bio: [
              "Matthew is a native of the San Francisco Bay Area and Silicon Valley's technological engine of innovation. As Qoor's founder, he has assembled an excellent leadership team, drawn from the most well-respected institutions our nation has to offer, to create a company of technological convergence - the nexus of artificial intelligence and blockchain.",
              'Before founding Qoor, Matthew lived and worked internationally for Fortune 500 companies, including Schlumberger, Ericsson, and Hitachi. He has consulted or worked on projects for companies including AT&T, VM Ware, Juniper Networks, Intuit, and Saudi Aramco. As a Veteran of the United States Navy, Matt has supported the US Government, Department of the Army, and Department of the Air Force in various roles overseas. He has lived and worked in Tunisia, Egypt, Italy, the United Arab Emirates, Saudi Arabia, and Singapore building strong leadership teams and establishing a continuing legacy of success.',
              "Matthew believes in the power of servant leadership and service to others. His broad experience includes connecting nations to the internet via fiber optics and satellites, to harnessing energy from the earth's abundant natural resources. Passionate about electromagnetic theory and a student of the Maxwellians, Matt is an avid technologist founded in software, advanced electronics, telecommunications, and electromechanical engineering.",
              'Matthew holds a BBA in Financial Consulting from the Cox School of Business at Southern Methodist University in Dallas, TX, with a minor in Asian Studies. He also studied Avionics and is a graduate of the US Naval Air Technical Training Center. Matthew is a lifelong student of world cultures, religions, and anthropology, including Europe, Africa, the Middle East, and Southeast Asia. He speaks Arabic, Mandarin Chinese, and Spanish.',
            ],
          },
          {
            image: '/avatars/haferman-jeff.png',
            first: 'Dr. Jeff',
            last: 'Haferman',
            text: 'Chief Technology Officer',
            link: 'https://www.linkedin.com/in/jeffhaferman/',
            bullets: [
              '10 years leading Supercomputer Operations at a Federal agency',
              'Intricate knowledge in Supercomputing and Data Analytics',
              'BS, Mathematics, University of Chicago',
              'MS / Ph.D., Mechanical Engineering, University of Iowa',
            ],
            bio: [
              "Computational Data Science and High-Performance Super-Computing are Dr. Haferman's wheelhouse. With over 25 years of experience in all aspects of High-Performance Computing (HPC), Supercomputing, Engineering, Mathematics, and Computer Science, he leads Qoor's technical efforts. Jeff currently leads Supercomputer Operations at a Federal agency. He is a subject matter expert in the areas of HPC, Data Analytics/Big Data, and Artificial Intelligence, Machine Learning, MPI programming, hybrid CPU-GPU programming, the Hadoop stack, R Programming, TensorFlow, and related packages.",
              'Jeff has taught graduate-level courses in Remote Sensing, Radiation Heat Transfer, and Mathematical Physics, and undergraduate level courses in Heat Transfer, Thermodynamics, and Advanced Calculus. He is the inventor of the "Haferman Carpet," a fractal featured on Wolfram MathWorld.',
              'Jeff earned a Bachelor of Science degree in Math and Computer Science from the University of Chicago, followed by a Masters and Ph.D. in Mechanical Engineering from the University of Iowa.',
              'Dr. Haferman was awarded the prestigious NASA Global Change Fellowship and completed his post-doctoral work at the NASA Goddard Space Flight Center in Greenbelt, Maryland. He has published in the areas of radiative transfer and satellite microwave remote sensing of precipitation.',
            ],
          },
          {
            image: '/avatars/rice-michael.png',
            first: 'Michael',
            last: 'Rice',
            text: 'Chief Operations Officer',
            link: 'https://www.linkedin.com/in/michaelrice243/',
            bullets: [
              'Retired US Navy Officer (SEAL; O-4, Lieutenant Commander)',
              'Program Manager, Global Aerospace Logistics (UAE)',
              'BSc Finance, George Washington University School of Business',
              'MBA, Naval Postgraduate School in Monterey, CA',
            ],
            bio: [
              'As a career Naval Officer, having served in the United States Marine Corps Special Operations, 1stForce Recon, and the United States Navy Special Operations SEAL Teams, Michael brings over 24 years of operational leadership experience to the Qoor Team. Michael supports Qoor\'s overall operations through team engagement, mentoring, and active leadership. His direct involvement in complex environments and an ability to maintain clear-eyed focus enables rapid and effective execution against Qoor\'s roadmap for success. You will find Mike side-by-side with the Qoor team, "lifting the boat."',
              "After Lieutenant Commander Rice (SEAL) retired from the US Navy, he supported the United Arab Emirates' Government as a Program Manager based in Abu Dhabi. Michael designed and implemented a comprehensive training program for the UAE Special Operations Command and the UAE Presidential Guard to increase operational effectiveness throughout the Arabian Gulf and Eastern Africa. With over two decades, leading cross-functional teams under extremely challenging circumstances, Michael is a crucial member of Qoor's Executive team. Michael holds a BSc in Finance from The George Washington University in Washington, DC and an MBA from the United States Naval Postgraduate School in Monterey, California.",
              'In his off time, Michael enjoys athletics including, kayaking, road bike racing, skydiving, and alpine mountaineering. He also enjoys traveling internationally with his wife.',
            ],
          },
          {
            image: '/avatars/golden-shane.png',
            first: 'Shane',
            last: 'Golden',
            text: 'Executive Vice President',
            link: 'https://www.linkedin.com/in/shane-alexander-golden-43368492/',
            bullets: [
              'Serial entrepreneur. Experience in leadership roles, building and managing projects.',
              '7-year cryptocurrency pioneer and blockchain validation consultant.',
              'Passionate about finance, emerging technologies, and people.',
              'Educated in Finance, Marketing, Sales, Business Management, and Mechatronic Systems Engineering.',
            ],
            bio: [
              "As the Executive VP at Qoor, Shane's responsibilities include: maintaining a healthy work environment and overall culture, supporting financial management and achievement of financial goals, sales innovations, strategic business development, and supporting the executive teams' daily problem-solving. Dynamic responsibilities and variable day-to-day roles of an Executive VP maintain Shane's motivation. Shane is a natural problem solver and demonstrated management and leadership skills within his previous company Goldenstrike Technologies Inc.",
              "Shane began CPU mining Bitcoin in 2011 and has continuously stood at the forefront of Cryptocurrency mine development in Western Canada. In early 2013, Shane started building, selling, servicing, and offering hosting packages for GPU miners. Later that year, he was given the opportunity by investors to help develop the first commercial mine in Western Canada. During this time, the introduction of ASIC (application-specific integrated circuit) miners brought a new wave of capability into the Crypto-mining market, and both Shane's private mining operation and hosting facilities proliferated. Utilizing his experience and knowledge to help others build and maintain profitable mining operations, Goldenstrike quickly grew into a Crypto-mining consulting firm. Frustrated with a lack of reliable and trustworthy mine management software, Shane brought on a development team to tackle a comprehensive solution; Ground Control, Goldenstrike's flagship product was born. Proving their capability as a software development firm, Goldenstrike began exploring Ground Control to support AI and Machine Learning. At this point, Shane and Matthew Heaviside were introduced. Soon after, Qoor acquired the Goldenstrike team. The united front, Qoor, is now supporting the AI and Machine Learning industry by providing infrastructure and platform solutions, empowering data scientists to develop AI/ML applications without the need for time-consuming DevOps or to purchase dedicated GPU's.",
              'Passionate about finance, emerging technologies, and people. Serial entrepreneur. Experience in leadership roles, building, and managing projects. Shane has an education in Finance, Marketing, Sales, Business Management, and Mechatronic Systems Engineering.',
            ],
          },
          {
            image: '/avatars/andrews-robert.png',
            first: 'Robert',
            last: 'Andrews',
            text: 'Sr. Vice President Data Centers',
            link: 'https://www.linkedin.com/in/robertandrewsjr/',
            bullets: [
              'Managed the design, construction management and commissioning of over 4M sq.ft. of redundant collocation and enterprise mission critical',
              'Designed & Built data centers globally since 1992 in the US, Canada, UK, Middle East, Singapore, Mexico and Chile',
              'Built collaborating teams of professionals including Architectural and MEP engineers to create master planning strategies and innovated design on data center Infrastucture.',
              'Consultant to the end user on sales tax and corporate tax incentives, speed to market, constructibility, fiber and power negotiation.',
            ],
            bio: [
              'Robert Andrews is Qoor’s Senior Vice President of Data Center infrastructure design and development. Before joining QOOR, Robert was the Global Director of Mission Critical for the world’s largest engineering firm. His extensive knowledge of mission-critical systems and facilities ensures Qoor’s data center engineering, deployment, management, and enhancement is second-to-none. In fact, you probably already use his systems!',
              'Robert has successfully delivered projects for AT&T, Apple, Google, Saudi ARAMCO, Bank of Oman, PACNET Asia, Ministry of Interior Saudi, Equinix, Century Link, Digital Reality Trust, Morgan Stanley, BB&T, Dell and Automotive Club of America totaling over 8 million square feet.',
              'As leaders in the mission critical industry, Qoor focuses on collaboration with some of the most experienced data center MEP and architectural design firms. Most importantly, continuing this collaboration with equipment and major sub-contractors who define best practices that ensure we are employing cutting edge, products and materials optimized for operations, maintenance, and sustainability.',
              'Robert holds a BS from Boston College and is a member of iMasons Infrastructure, 7x24 National and Regional and Uptime Institute.',
            ],
          },
          {
            image: '/avatars/fifer-matt.png',
            first: 'Matthew',
            last: 'Fifer',
            text: 'VP of Operations',
            link: 'https://www.linkedin.com/in/mfifer/',
            bullets: [
              'Over 25 years of across-the-board IT experience',
              '16 years of IT leadership at a major value investment firm',
              '2 years of consulting on information security and modern IT strategies',
            ],
            bio: [
              'Matthew Fifer is a lifetime technologist. Born at Ft. Meade Maryland, his childhood was defined by computing. From operating punch card machines as a toddler to teaching BASIC programming as a youth, he is a consummate technophile and supports Qoor’s operations with a level of knowledge and intimacy that cannot be taught. Matthew has always been at the forefront of technological advancement and radical disruption—he sees the promise that both blockchain and artificial intelligence hold for our world.',
              'Working for Northwestern University as an emerging technologist, he recognized the potential of the world wide web. It inspired Matthew to develop one of the very first online learning applications for Northwestern University’s College of Arts and Science.',
              'In 1998, he was recruited to join the Baupost Group in Cambridge, Massachusetts. Mr. Fifer built the IT practice, developing policies and procedures, delivering reliable IT services, and gaining a reputation for his ability to identify beneficial emerging technologies. As The Baupost Group’s Chief Technology Officer, he guided the firm’s IT program with an eye toward matching business needs with technological solutions.',
              'Matthew studied computer science and electrical engineering at Northwestern University.',
            ],
          },
          {
            image: '/avatars/farnum-jerome.png',
            first: 'Jerome',
            last: 'Farnum',
            text: 'VP of Design',
            link: 'https://www.linkedin.com/in/jeromefarnum/',
            bullets: [
              '15 years designing and developing UI/UX that drives engagement',
              'Unique design perspective and background building user experiences',
              'Leadership roles at Electronic Arts, Bandai Namco, Gamania Digital Entertainment and Aeria Games',
            ],
            bio: [
              "As Qoor's resident champion for the user, Jerome leverages a lifetime of experience designing and building engaging and effective digital experiences to ensure Qoor's suite of products encapsulate robust capabilities in a delightful and effortless package. Jerome spearheads Qoor's branding, marketing, user experience, and front-end development initiatives to ensure alignment with Qoor's ethos of quality and user empathy.",
              'Jerome is a master craftsman with an appreciation for careful planning, the creative eye, and steady execution. His lifelong passion for design and coding is grounded in the gaming and entertainment industry, and he has played an integral role at companies including Bandai Namco and Electronic Arts. Jerome thrives in design leadership roles, with a track record of delivering exemplary work on critical projects. His extensive experience in design and coding empowers Jerome to synergize design with engineering, effectively communicating requirements, challenges, and solutions to all stakeholders.',
              'When not tackling design challenges, Jerome enjoys cooking for his wife, following all things hockey, and hunting for the next great science-fiction read.',
            ],
          },
          {
            image: '/avatars/manansala-sheldan.png',
            first: 'Sheldan',
            last: 'Manansala',
            text: 'Program Manager',
            link: 'https://www.linkedin.com/in/sheldanmanansala/',
            bullets: [
              'Former Supplier Quality and Test Engineer at Tesla Motors.',
              'Experience managing cross-functional engineering teams leading cost down initiatives and process automation projects.',
              'Deep experience in blockchain validation hardware and software.',
              'BASc Mechatronic Systems Engineering, Simon Fraser University.',
            ],
            bio: [
              'Sheldan started building things on his 5th birthday when he received his first limited addition Star Wars Lego set. Since then he has helped build self-driving cars, battery modules that can power a small town and software applications that help manage data centers. There is something about watching a product go from design to final product that fascinates him.',
              'Sheldan holds a Bachelor of Applied Science from Simon Fraser University (British Columbia, Canada) with a specialty in Mechatronic Systems Engineering. As former Director of Operations at Goldenstrike Technologies Inc., Sheldan utilized his expertise in developing profitable cryptocurrency mining strategy and software tools to aid in data center management and maintenance. With lead engineering roles at companies like Tesla Motors and Alpha Technologies Inc, Sheldan continuously plays a key role in cost down initiatives and process automation projects.',
              'As the Program Manager at Qoor, Sheldan’s responsibilities include: organizing programs and activities in accordance with the mission and goals of the organization; managing the product strategy and development life-cycle; meeting with key project stakeholders to facilitate easy communication and provide transparency; managing cross-functional teams with diverse talents and responsibilities; develop evaluation methods to assess program strengths, weaknesses, quality and effectiveness as well as ensuring the project remains within budget and on schedule. Operational efficiency is his core strength: he helps to successfully design, develop and deploy premium products to satisfy the needs of the customer.',
            ],
          },
          {
            image: '/avatars/blaszak-jo.png',
            first: 'Jo',
            last: 'Blaszak',
            text: 'Junior Software Project Manager',
            link: 'https://www.linkedin.com/in/joblaszak/',
            bullets: [
              "Various roles at Canada's Particle Accelerator Centre and the Max Planck Institute for Nuclear Physics, Heidelberg, Germany.",
              'Experience with automating system processes and creating innovative software solutions.',
              'BASc. Integrated Engineering, University of British Columbia.',
            ],
            bio: [
              "At Qoor, it is Jo's role to ensure that every software component in the stack integrates with the rest to provide a seamless user experience for the client. He drives design decisions based on first principles while also looking ahead to see things not only as they are now but where they will be and preparing for that. His previous experience designing and implementing a complete mine management software providing real time data collection and analytics for thousands of machines at Goldenstrike Technologies serves him well in accomplishing this.",
              "A natural born problem solver, Jo looks less at the what and more at the why and the how of the situation. This approach has given him a zest for learning new skills and technologies, developing deep undestandings of not only the different puzzle pieces but also in how they fit together. Professionaly, Jo has been involved in an array of technical disciplines spanning everything from web design, code consulting, nuclear physics and software development. He holds a Bachelors of Applied Science from the Integrated Engineering program at the University of British Columbia, Canada. There he led a team of students developing semi-autonomous robotics systems, managed his department club's funds, and handled the logistics for a conference.",
              'Using his broad range of skillsets he is able to look at problems from many different angles to create innovative solutions in a fast, efficient manner while meeting key design objectives of multiple share holders. Interested in the latest advancements in science and technology, Jo is extremely passionate about Artificial Intelligence and other exponential growth opportunities.',
            ],
          },
          {
            image: '/avatars/domanski-michal.png',
            first: 'Michal',
            last: 'Domanski',
            text: 'Experience Designer',
            link: 'https://www.linkedin.com/in/michal-domanski',
            bullets: [
              'Entrepreneur with experience product design and project management.',
              '5 years of experience in blockchain technology.',
              'Education and experience in human centric design.',
            ],
            bio: [
              'Since his very first Windows NT computer, Michal was fascinated by all things related to technology and thrilled when encountering challenges that called for creative problem-solving solutions. Design is to solve a problem, as the world evolves, people evolve. As a designer, we must stay ahead of the curve to shape things that will help people live better lives. Design is the constant puzzle Michal is determined to solve.',
              'Michal holds a dual diploma in User Experience Design and, Business Management along with a Product Management certificate and Scrum Master certification. As the CEO & Founder of PriorityCrypto, He is familiar with the start-up hustle and rolling with the fast moving-tech (such as the blockchain) and how that affects the build schedules and backlog. With this experience, Michal can implement design thinking along with the business needs and requirements in mind. His previous experience as a project manager working in a cross-functional environment help him deliver technical leadership in his future roles.',
              'As Qoor’s leading Junior designer, Michal brings the ability to design interactions that are effortless and help the user be successful in achieving their goal. He brings the experience to deliver pixel-perfect front-end design that meets the requirements of cross-functional teams. He has a profound ability to create engaging animations and deliver compelling prototypes.',
              'When not at work, Michal enjoys outdoor activities such as playing soccer, scaling up mountains on hiking adventures and, being at the forefront of emerging frameworks and technologies.',
            ],
          },
        ]}
      />
      <Profiles
        title='Advisors'
        profiles={[
          {
            image: '/avatars/baron-patrick.png',
            first: 'Patrick',
            last: 'Baron',
            text: 'Blockchain Technology',
            link: 'https://www.linkedin.com/in/patricknbaron/',
            bullets: [
              'CEO, Blockchain Consulting Group',
              'Advisor, Blockchain at UC Berkeley',
              'Former President, Orderbook',
              'Former President, Ambisafe',
              'Co-founder, HitFin',
              'BS, Wake Forest University',
              'Cohort 5, Blockchain University',
            ],
            bio: [
              'Patrick is the founder and CEO of Blockchain Consulting Group based in San Francisco, California. He advises blockchain startups with their go-to-market strategy, application development, and Security Token Offerings. Patrick provides blockchain education in the form of workshops, online courses, and public speaking engagements. He also advises policymakers, advocating for responsible adoption and promotion of blockchain technology.',
              'Prior to his current role, he was President of Ambisafe, a global blockchain software advisory firm. In that role oversaw sales and business development where he helped grow the company from 5 to over 120 employees, advised on dozens of ICOs which raised several hundred million dollars in crypto-funding.',
              'While at Ambisafe he also took on the role of President at Orderbook.io, Ambisafe’s token sales platform and decentralized exchange (DEX). Orderbook achieved several ‘firsts’ including; first ICO launched via DEX (Propy / $13M), first ERC20 token backed by Bitcoin, and first ‘regulatory aware’ token that programmatically enforces legal and regulatory requirements to ensure compliance.',
              'Prior to Ambisafe, he was cofounder if Hitfin, an Ethereum based derivatives trading platform that utilized smart contracts, oracles, and a native fiat backed stable coin to trade and settle bilateral OTC trades on-chain.',
              'Patrick has over a decade of additional sales and business development experience in the financial services, renewable energy, and software industries. He earned a Bachelor of Science in Business from Wake Forest University.',
            ],
          },
          {
            image: '/avatars/batschelet-al.png',
            first: 'Al',
            last: 'Batschelet',
            text: 'Corporate Governance',
            link: 'https://www.linkedin.com/in/albatschelet/',
            bullets: [
              'Retired Chief of Staff, United States Strategic Command, US Army (O8, Major General)',
              'Principal & Co-Founder, Horizon Strategies, LLC',
              'President & CEO, First Responders Foundation',
              'BS, History & Political Science, Iowa State University',
              'MS, US Army War College',
              'International Relations, NATO Defense College',
            ],
            bio: [
              'Al completed his service as a Soldier and began his tenure as a principal and co-founder of Horizon Strategies in September 2016. He brings a demonstrated record of successes as a skilled corporate senior executive of large organizations in the U.S., Europe, the Middle East, and Asia. He specializes in strategy and planning, executive leadership and workforce development, organization and operations, strategic alliances and partnering, and risk management. He has substantial experience in human resources, budgeting and forecasting, as well as advertising, public relations, and communications.',
              'Al grew up on a small farm in rural Iowa, where his family raised corn, soybeans, hogs, cattle and even a few sheep. While attending Iowa State University, he joined the Iowa National Guard and participated in the ROTC program to help him pay for college. By the time he graduated with a bachelor’s degree in history in 1983, he had earned his commission as a Second Lieutenant.',
              'After graduation, Al dedicated himself to a 33-year Army career, eventually rising through the ranks to Major General. Throughout his career, he was devoted to solving complex national security challenges in peace and war, while always focusing on developing and leading Soldiers, and caring for them and their families.',
              'Al leveraged the opportunity to continue his education while in the Army, and earned three master’s degrees in strategy, national policy, and leadership, through the Army’s education programs. The knowledge he gained helped enhance and develop his critical thinking, leadership, and strategic planning skills.',
              'Among his key assignments as a senior executive, he oversaw the Army’s talent acquisition enterprise at the U.S. Army’s Recruiting Command. Additionally, he helped manage America’s strategic, nuclear, cyber and space forces at U.S. Strategic Command. He headed many and varied organizations and enterprises, ranging in size from hundreds to tens of thousands of personnel, with budgets varying from millions to billions of dollars.',
              'Al’s lifelong commitment to service and duty, as well as an unwavering commitment to his personal and professional values, consistently results in achieving desired outcomes and client satisfaction. He believes success is found in using creative, collegial and disciplined approaches. Chief among his priorities is a focus on frequent personal engagement, transparent communication, timely delivery, and quality verification.',
            ],
          },
          {
            image: '/avatars/sonak-jane.png',
            first: 'Jane',
            last: 'Sonak',
            text: 'Cyber Security',
            link: 'https://www.linkedin.com/in/jane-sonak-29b7a12b/',
            bullets: [
              'Retired US Army Signal Corps Officer (O-5, Lieutenant Colonel)',
              'Former Director of Cyber Security for the US Army',
              "Associate Professor, Augusta University's Cyber Institute",
              'BS, Northeastern State University / MBA, University of Colorado',
            ],
            bio: [
              'Jane is the former Director of Cyber Security for the United States Army and is Qoor’s Chief Security Officer. She is a high-performing, high-energy senior IT and Cyber executive with over 24 years of successful development and execution of strategic initiatives. She is adept at building and optimizing organizational processes, integrating innovative technologies, and delivering services to meet business needs. Jane’s reputation in cybersecurity, insider threat protection, and track record of cooperation with Federal law enforcement and the Intelligence community is a testament to her ability as a leader.',
              'Jane is a skilled strategist who develops and transforms strategic plans into workable solutions while benchmarking performance against critical operational targets and goals. Jane’s natural ability to intuitively identify issues and solve problems prior to impact is an essential asset to Qoor and our Executive Management Team. Jane’s enthusiasm and spirit are contagious—a wellspring of motivation in the halls of Qoor.',
              'After Lieutenant Colonel Sonak retired from the United States Army, she has made strides in the private sector, including her most recent role as a Senior Manager for SAIC.',
              "Jane holds a BS in Cellular Biology and Chemistry from Northeastern State University and earned her MBA in IT Management from the University of Colorado, Colorado Springs. She is also an Associate Professor at Augusta University's Cyber Institute where she instructs the next generation in IT, business, and ethics.",
            ],
          },
          {
            image: '/avatars/warren-paul.png',
            first: 'Paul',
            last: 'Warren',
            text: 'Real Estate Strategy',
            link: 'https://www.linkedin.com/in/paulwarren/',
            bullets: [
              'Chief Strategy Officer, ALDAR',
              'Chief Strategy Officer, Sorough',
              'Managing Partner, Antipodean Partners',
              'Vice President, J.P. Morgan',
              'Assistant Secretary for Security, Royal Hong Kong Government (UK)',
              'Detective Senior Inspector, Special Branch, Royal Hong Kong Police (UK)',
              'BA, Law & Politics, University of Otago',
            ],
            bio: [
              'Paul is an experienced Senior Executive with a demonstrated history of working in the venture capital, private equity, financial services, and real estate sectors. He is a current resident of the United Arab Emirates where he heads a private consulting firm.',
              'Prior to his principal role, Paul was Chief Strategy Officer for ALDAR Properties where he served on the Executive Management Committee overseeing Strategy Planning (strategy, business planning, risk management, business performance, KPIs, culture), Strategic Investments (portfolio companies including Pivot Construction, Khidmah and Aldar Academies), and Investment Control.',
              'Paul has a successful track record of global leadership at recognized institution including J.P. Morgan, where he served as Vice President, M & A Financial Services.',
              'He is a former Assistant Secretary for Security for the Royal Hong Kong Government (UK) and Detective Senior Inspector – Special Branch, Royal Hong Kong Police (UK).',
              'Paul holds a BA in Law & Politics from the University of Otago.',
            ],
          },
          {
            image: '/avatars/krongard-alex.png',
            first: 'Alex',
            last: 'Krongard',
            text: 'Capital Markets Strategy',
            link: 'https://www.linkedin.com/in/alex-krongard-7429b112a/',
            bullets: [
              'Retired US Navy, Commander of Naval Special Warfare Group 1 (O7, Rear Admiral; SEAL)',
              'National Security Council, Washington DC',
              'Managing Director, DCS Advisory',
              'BA, English, Princeton University',
              'DG, National War College',
            ],
            bio: [
              'Based in San Francisco, Alex is a Managing Director at DCS Advisory, where he leads the firm’s Aerospace, Defense & Government Service, Cybersecurity, Risk & Compliance and Security & Cloud Services groups. He brings 30 years of executive experience to client engagements.',
              'Before joining DCS Advisory, he was a Naval Special Warfare officer and held high-level positions influencing military strategy and defense policy on the National Security Council staff and at several senior-level military headquarters. He began his military career as a Navy SEAL and served in multiple locations in Europe, East Asia, Africa, and the Middle East before retiring as Rear Admiral.',
              'Alex commanded Naval Special Warfare Group 1 in Coronado, California, and was responsible for preparing West Coast SEAL Teams for their operational deployments overseas. While in command of Group 1 he deployed to Kabul as the deputy commander for the Combined Forces, Special Operations Component Command-Afghanistan.',
              'Alex also served as the deputy director for operations, U.S. Africa Command, in Stuttgart, Germany. In this position, he supported the Director for Operations by providing oversight and direction to the Current Operations, Future Operations, Information Operations, Counter-Terrorism, and Special Operations Divisions.',
              'He is a graduate of the National War College and served as an executive assistant to the Deputy Chief of Naval Operations for information, plans, and strategy as well as the chief of staff at Special Operations Command Central. He has also served on the National Security Council staff in Washington, D.C., as a counterterrorism director.',
              'Alex earned a bachelor’s degree in English from Princeton University and is a distinguished graduate of the National War College.',
            ],
          },
          {
            image: '/avatars/corbin-michael.png',
            first: 'Hon. Michael',
            last: 'Corbin',
            text: 'Government Relations',
            link: 'https://www.linkedin.com/in/michael-corbin-b4120110/',
            bullets: [
              '29 Years in U.S. Foreign Service',
              'United States Ambassador, U.A.E.',
              'United States Ambassador, Syria',
              'Deputy Assistant U.S. Secretary of State, Near East',
              'BA, International Relations, Swarthmore College',
            ],
            bio: [
              'Ambassador Michael Corbin, a career diplomat with extensive experience in the Middle East, is the outgoing U.S. Ambassador to the United Arab Emirates. He finished his term in Abu Dhabi in December 2014.',
              'During his tenure, Ambassador Corbin helped to strengthen U.S.- UAE business relations across all sectors, resulting in a significant expansion in U.S. companies exporting to the UAE.',
              'Ambassador Corbin has significant expertise and experience in the Middle East, having served in Iraq, Syria, Egypt, Tunisia, and Kuwait during his career in the Foreign Service in addition to his service as Ambassador to the UAE.',
              'Before his service as Ambassador, he most recently served as Deputy Assistant Secretary of State in the Bureau of Near Eastern Affairs. Before that, he was Minister-Counselor for Political-Military Affairs at the U.S. Embassy in Baghdad from 2008 to 2009 and Chargé d’Affaires at the U.S. Embassy in Damascus, Syria from 2006 to 2008.',
              'Ambassador Corbin served as Minister Counselor for Economic and Political Affairs at the U.S. Embassy in Cairo from 2003 to 2006. He was Deputy Director of the Office of Arabian Peninsula Affairs in the Bureau of Near Eastern Affairs from 2001 to 2003. Ambassador Corbin served as Director of the Counter-Narcotics Section at the U.S. Embassy in Caracas, Venezuela from 1997 to 2001 and Political-Military Affairs Officer at the U.S. Embassy in Cairo, Egypt from 1994 to 1997. His first overseas post was at the U.S. Embassy in Tunis, Tunisia from 1985-1987. He served in Kuwait from 1987 to 1989. Ambassador Corbin joined the Foreign Service in 1985.',
              'Prior to joining the Foreign Service, Ambassador Corbin served in the Peace Corps from 1982 to 1984 in Mauritania. He received a bachelor’s degree from Swarthmore College. He speaks Arabic, French, and Spanish.',
            ],
          },
          {
            image: '/avatars/untracht-bob.png',
            first: 'Robert',
            last: 'Untracht',
            text: 'Finance & Accounting Governance',
            link: 'https://www.linkedin.com/in/robert-untracht-1033b718/',
            bullets: [
              '2 Current Board positions',
              '4 Previous Board of Director positions',
              'Former Professor, Accounting, at UCLA Anderson School of Management and NYIT (NYC)',
              'Retired Audit Partner and Former National Director-Ernst & Young LLP',
              'Former Manager, Deloitte & Touche',
              'BS, Computer Technology, New York Institute of Technology',
              'MBA, State University of New York',
              'JD, Southwestern University School of Law',
            ],
            bio: [
              'Robert J. Untracht (Bob) advises Qoor financial and accounting matters. He has served as an Advisor to Hexanika, Inc., on the Board of Directors of Shift7 Digital (Audit Committee member), American Mobile Power Systems, Inc., Discus Dental LLC (Audit Committee Chairman and Special Committee which sold the company), Doubleline Funds (Audit Committee Chairman), SM&A, Inc. (Audit Committee Chairman and as Chairman of the Special Committee sold the company). Bob also served as a consultant to ILFC (an aircraft leasing company). He also has taught accounting classes at the Andersen School of Management of UCLA and business law and accounting courses as an Associate Professor of Accounting at the New York Institute of Technology.',
              'Bob is a retired partner of Ernst & Young LLP. He held several positions with E&Y ranging from Manager to Audit Partner (in Century City, CA) and National Director of Retail and Consumer Product Industry Services (in NYC) where he served clients in the retail and consumer products, entertainment, real estate, and aircraft leasing industries. Previously with Deloitte & Touche LLP, he served clients in the aerospace, defense, and manufacturing industries. He also held the position of corporate accounting manager at Bonwit Teller, a high fashion retail chain.',
              'Bob holds a B.S. in Computer Technology from the New York Institute of Technology and an M.B.A. in Finance and Accounting from the State University of New York at Buffalo. He also holds a J.D. from Southwestern University School of Law in Los Angeles and Certificate from The John E. Anderson Graduate School of Management at UCLA, for the Completion of the "Director Training & Certification Program." He is a CPA in New York, a member of the AICPA, and the NY State Society of CPAs. He is also admitted to the State Bar of California and is currently active.',
              'Bob maintains a bicoastal lifestyle residing in both Manhattan Beach, California and New York City, NY. He and his wife Marcia have two children.',
            ],
          },
        ]}
      />
      <Partners
        background='#021B33'
        title='Partners & Technology'
        partners={[
          <div>
            <SVGImage variant='PartnerNvidia' />
          </div>,
          <div>
            <SVGImage variant='PartnerGoodwin' />
          </div>,
          <div>
            <SVGImage variant='PartnerFireEye' />
          </div>,
        ]}
      />
    </PageContent>
  </Layout>
)

export default AboutPage
