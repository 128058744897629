import React, { Fragment } from 'react'
import { PartnersWrapper, PartnersText, PartnersContainer } from './Partners.css'
import { useKinetiq } from 'kinetiq'

export const Partners = ({ background, title, partners }) => {
  const kinetiq = useKinetiq()

  return (
    <div css={PartnersWrapper(background)}>
      <div></div>
      <div css={PartnersText(kinetiq.base)}>
        <h1>{title}</h1>
      </div>
      <div css={PartnersContainer(kinetiq.base)}>
        {partners.map((partner, index) => (
          <Fragment key={index}>{partner}</Fragment>
        ))}
      </div>
    </div>
  )
}
